<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="rounded ma-2" tile color="primary" v-bind="attrs" v-on="on">
        <v-icon left> mdi-plus </v-icon>
        Add Manual Order
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Add Order</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-autocomplete
                v-model="newOrder.registrant"
                :items="allUsers"
                :item-text="item => item.contact.givenName + ' ' + item.contact.surname"
                item-value="_id"
                label="Select Registrant"
                @change="getPackages(newOrder.registrant)"
                required
              />
            </v-col>
            <v-col cols="12" sm="12">
              <template v-if="selectPackageLoader">
                <v-progress-linear indeterminate color="primary" />
              </template>
              <v-autocomplete
                v-if="showProducts"
                v-model="newOrder.package"
                :items="packageItems"
                item-text="name"
                item-value="_id"
                label="Select Packages"
                @change="fetchPackage(newOrder.package)"
                required
              />
            </v-col>
            <v-row>
              <template v-for="pkg in userPackage">
                <v-col :key="pkg._id" cols="12" lg="12" md="12" sm="12">
                  <v-card>
                    <v-card-title>{{ pkg.name }}</v-card-title>
                    <v-card-subtitle>{{ pkg.description }}</v-card-subtitle>
                    <v-card-text>
                      <v-card elevation="0">
                        <v-card-text>
                          <strong>Package Includes</strong>
                          <ul>
                            <li
                              v-for="club_product in pkg.club_products"
                              :key="club_product._id"
                            >
                              {{ club_product.name }}
                              <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-if="
                                      direct_product.requires_health_statement
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-medical-bag
                                  </v-icon>
                                </template>
                                <span>Requires Health Statement</span>
                              </v-tooltip>
                            </li>
                            <li
                              v-for="direct_product in pkg.direct_products"
                              :key="direct_product._id"
                            >
                              {{ direct_product.name }}
                              <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-if="
                                      direct_product.requires_health_statement
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-medical-bag
                                  </v-icon>
                                </template>
                                <span>Requires Health Statement</span>
                              </v-tooltip>
                            </li>
                          </ul>
                        </v-card-text>
                      </v-card>
                      <p class="mt-5 h6">
                        Price: ${{ formatCurrency(packagePrice(pkg._id)) }}
                      </p>
                    </v-card-text>
                    <v-card-actions class="button-set">
                      <v-spacer />
                      <template
                        v-if="
                          pkg.direct_products.some(
                            (element) =>
                              element.requires_health_statement === true
                          ) ||
                          pkg.club_products.some(
                            (element) =>
                              element.requires_health_statement === true
                          )
                        "
                      >
                        <health-statement />
                      </template>
                      <template
                        v-if="
                          pkg.direct_products.some(
                            (element) => element.product_entry.length
                          ) ||
                          pkg.club_products.some(
                            (element) => element.product_entry.length
                          )
                        "
                      >
                        <prize-destination-form />
                        <upload-medical />
                        <product-entry-form :package-id="pkg" />
                      </template>
                      <v-btn
                        :disabled="
                          pkg.direct_products.some(
                            (element) =>
                              element.requires_health_statement === true
                          ) && newHealthStatementCreated !== true
                        "
                        @click.stop="createOrder(pkg._id)"
                      >
                        Purchase
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </template>
              <v-snackbar v-model="showPaymentFailureMessage" timeout="5000">
                <template>
                  <h4>Order not placed</h4>
                  The order was not placed, please ensure you have entered
                  credit card details correctly and try again later.
                  <div>Order has not been placed.</div>
                </template>
              </v-snackbar>
              <v-dialog v-model="paymentDialog" persistent max-width="600px">
                <v-card>
                  <v-card-title>Enter Payment Details</v-card-title>
                  <v-container ref="paymentCard" />
                  <v-card-actions>
                    <v-btn
                      color="darken-1"
                      text
                      @click="onStripeElementCancel()"
                    >
                      close
                    </v-btn>
                    <v-btn
                      v-if="paymentSubmittable"
                      color="primary darken-1"
                      text
                      @click="checkout"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="resetForm"> Close </v-btn>
        <v-snackbar v-model="snackbar" :timeout="timeout">
          {{ text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { utilities } from "../../../../mixins/utility";
import HealthStatement from "../../registrant/HealthStatementDiag2.vue";
import UploadMedical from "../../registrant/UploadMedical.vue";
import ProductEntryForm from "../../registrant/ProductEntryForm.vue";
import PrizeDestinationForm from "../../registrant/prizeDestinationForm.vue";

const stripe = Stripe(
  "pk_live_51J4yHULmS5CcBkMzb8Epahll1kzy09X3T3chjV9Bd9ODJS1jteMXnGr98CC48e05Wvp4BYTF0VjyoX8bxG7yn9uC00FYfz48Nv"
);

const elements = stripe.elements();
let card;

export default {
  name: "NewOrder",
  components: {
    VuePerfectScrollbar,
    HealthStatement,
    UploadMedical,
    ProductEntryForm,
    PrizeDestinationForm,
  },
  mixins: [utilities],
  data: () => ({
    snackbar: false,
    dialog: false,
    showProducts: false,
    text: "Task Name Must Be Required...",
    showPaymentFailureMessage: false,
    paymentSubmittable: true,
    paymentDialog: false,
    timeout: 2000,
    drawer: null,
    selectPackageLoader: false,
    newOrder: {
      registrant: "",
      package: "",
    },
    packageItems: [],
    userPackage: [],
  }),
  computed: {
    ...mapGetters([
      "allUsers",
      "newHealthStatement",
      "newHealthStatementCreated",
      "clientSecret",
      "orderEntry",
      "getOrderInProgressId",
    ]),
  },
  created() {
    this.$store.dispatch("getAllUsers", "Registrant");
  },
  methods: {
    //map action for api here
    ...mapActions([
      "fetchPackageByRegistrant",
      "getRegistrantPackages",
      "createHealthStatement",
      "createRegistrantOrder",
      "cancelRegistrantOrder",
      "sendOrderFinalizeFlag",
    ]),
    ...mapMutations([
      "SET_PRIZEDESTINATIONDIALOG_STATE",
      "SET_ORDER_IN_PROGRESS_ID_",
      "CLEAR_ORDER_IN_PROGRESS_ID_",
    ]),
    resetForm() {
      (this.dialog = false),
        Object.assign(this.$data, this.$options.data.call(this));
    },
    getPackages(personId) {
      this.showProducts = false;
      this.selectPackageLoader = true;
      this.packageItems = [];
      this.userPackage = [];
      this.fetchPackageByRegistrant(personId).then((result) => {
        let items = result.data;
        let arrayOfProducts = [];
        items.forEach((element) => {
          arrayOfProducts.push(element);
        });
        this.showProducts = true;
        this.selectPackageLoader = false;
        this.packageItems = [...arrayOfProducts];
      });
    },
    fetchPackage(packageId) {
      let findPackage = this.packageItems.filter(
        (ele) => packageId === ele._id
      );
      if (findPackage) {
        this.userPackage = findPackage;
        console.log("PACKAGES SELECTED", this.userPackage);
      }
    },
    packagePrice(packageId) {
      const productPackage = this.packageItems.filter(
        (productPack) => productPack._id === packageId
      );
      const clubPriceableProducts = productPackage[0].club_products.map(
        (product) => {
          return {
            productPrice: Number(product.club_price),
            requiresHealthStatement: false,
          };
        }
      );
      const clubPrice = clubPriceableProducts.reduce(
        (acc, product) => acc + Number(product.productPrice),
        0
      );
      const directPriceableProducts = productPackage[0].direct_products.map(
        (product) => {
          return {
            productPrice: Number(product.default_price),
            requiresHealthStatement: product.requires_health_statement,
          };
        }
      );
      const directPrice = directPriceableProducts.reduce(
        (acc, product) => acc + Number(product.productPrice),
        0
      );
      return Number(clubPrice + directPrice);
    },
    onStripeElementCancel() {
      // Handles cancelings stripe card detail entry
      if (this.getOrderInProgressId) {
        this.cancelRegistrantOrder(this.getOrderInProgressId).then(() => {
          this.paymentDialog = false;
        });
      } else {
        this.paymentDialog = false;
      }
    },
    createOrder(packageId) {
      this.paymentDialog = true;
      const options = {
        style: {
          base: {
            color: this.$vuetify.theme.dark ? "#fff" : "#000",
          },
        },
        hidePostalCode: true,
      };
      this.$nextTick(function () {
        card = elements.create("card", options);
        card.mount(this.$refs.paymentCard);
      });
      const order = {
        packageId: packageId,
        paymentMethod: "card",
        healthStatement: this.newHealthStatement,
        orderEntries: this.orderEntry,
      };
      this.createRegistrantOrder(order).then(({ orderId }) => {
        this.orderId = orderId;
      });
    },
    checkout() {
      this.paymentSubmittable = false;
      stripe.createToken(card).then((result) => {
        if (result.error) {
          this.onStripeElementCancel();
          const targetElement = elements.getElement("card");
          targetElement.clear();
          this.paymentSubmittable = true;
          this.showPaymentFailureMessage = true;
          throw new Error("No payment details entered!");
        }

        stripe
          .confirmCardPayment(this.clientSecret, {
            payment_method: {
              card: card,
            },
          })
          .then((res) => {
            if (res.error) {
              console.log("ERROR with stripe res confirm payment");
              throw new Error(`Error - ${JSON.stringify(res.error)}`);
            }
            this.CLEAR_ORDER_IN_PROGRESS_ID_();
            // const targetElement = elements.getElement('card')
            // targetElement.destroy()
            // elements = stripe.elements()
            this.sendOrderFinalizeFlag(this.orderId);
            if (res.paymentIntent.status === "requires_capture") {
              this.SET_PRIZEDESTINATIONDIALOG_STATE(true);
              this.paymentSubmittable = true;
              // this.$router.push('/registrant/orders')
            }
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.apps-wrapper {
  .nested-sidebar-toggle {
    @media (min-width: 959px) {
      display: none;
    }
  }
}
.app-content-wrapper {
  position: relative;
  display: flex;
  overflow: hidden;
  height: calc(100vh - 200px);

  .app-overlay {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: none;
    z-index: 2;
    transition: all 0.3s ease-in;
    @media only screen and (max-width: 959px) {
      &.open {
        display: block;
        transition: all 0.3s ease-in;
      }
    }
  }
  .app-sidebar {
    width: 280px;
    position: relative;
    transition: all 0.3s ease-in;
    @media only screen and (max-width: 959px) {
      position: absolute;
      left: -340px;
      z-index: 5;
      height: calc(100vh - 120px);
      transition: all 0.3s ease-in;
      &.open {
        left: 0;
      }
    }
    .app-sidebar-header {
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
    .app-sidebar-body {
      // overflow-y: scroll;
      // height: 100%;
      padding-top: 3.375rem;
      width: 280px;
    }
  }

  .app-content {
    width: calc(100% - 280px);
    @media only screen and (max-width: 959px) {
      width: 100%;
    }
    // margin-left: 30px;
    .app-header {
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
    .app-body {
      flex: 1 1 auto;
      padding-top: 3.375rem;
      // overflow-y: scroll;
      height: calc(100% - 130px);
    }
    .app-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 130px;
    }
  }
}
.app-icon {
  display: none;
  @media only screen and (max-width: 959px) {
    display: block;
  }
}
.eg-filemanager {
  background-color: #fff;
}
.filemanager-content {
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
  &.--filemanager {
    .nested-sidebar-toggle {
      @media (min-width: 959px) {
        display: none;
      }
    }
  }
}
</style>
