<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <new-order />
          </div>
        </v-card-title>
        <v-card-title>
          Orders
          <v-spacer />
          <v-spacer />
          <v-spacer />
          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="allOrders"
          item-key="name"
          show-select
          class="elevation-1 table-one"
          multi-sort
          sort-by="timestamp_created"
          :sort-desc="[false]"
        >
          <!-- <template v-slot:item.person="{item}">
            <template v-if="item.person">
              {{ item.person.contact.givenName + ' ' + item.person.contact.surname || 'Not Provdived ' }}
            </template>
          </template> -->
          <template v-slot:item.timestamp_created="{ item }">
            {{ formatTimeStamp(item.timestamp_created) }}
          </template>
          <template v-slot:item.action="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                    @click="editItem(item)"
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="danger"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                    @click="deleteOrder(item._id)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { utilities } from "@/mixins/utility";
import NewOrder from "./NewOrder";
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Orders",
  },
  components: {
    NewOrder,
  },
  mixins: [utilities],
  data() {
    return {
      search: "",
      selected: [],
      headers: [
        { text: "First Name", value: "person.contact.givenName" },
        { text: "Last Name", value: "person.contact.surname" },
        { text: "Date Submitted", value: "timestamp_created", sortable: true },
        { text: "Order Status", value: "order_status" },
        { text: "Payment Status", value: "payment_status" },
        { text: "Action", value: "action" },
      ],
    };
  },
  computed: {
    ...mapGetters(["allOrders"]),
  },
  created() {
    this.getAllOrders();
  },
  methods: {
    ...mapActions(["getAllOrders", "deleteOrder"]),
    editItem(item) {
      this.$router.push(`/admin/orders/${item._id}`);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
