<template>
  <v-dialog
    v-model="prizeDestinationDialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Prize Money Destination:</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <p>
            Please specifify the entity and bank details you'd like your prize
            money to be deposited to.
          </p>

          <v-text-field
            v-model="bankAccountDetails.account_name"
            type="text"
            label="Account Name"
          />

          <v-text-field
            v-model="bankAccountDetails.bank_name"
            type="text"
            label="Bank Name (eg. Commonwealth Bank)"
          />

          <v-text-field
            v-model="bankAccountDetails.bsb"
            :rules="bsbRules"
            type="number"
            label="BSB"
          />

          <v-text-field
            v-model="bankAccountDetails.account_number"
            type="number"
            :rules="accountNumberRules"
            label="Account Number"
          />

          <v-text-field
            v-model="bankAccountDetails.abn"
            type="number"
            :rules="abnRules"
            label="ABN (If Applicable)"
          />

          <v-switch
            v-if="bankAccountDetails.abn"
            v-model="bankAccountDetails.registered_for_gst"
            label="Registered for GST"
          />

          <v-switch
            v-if="!bankAccountDetails.abn"
            v-model="bankAccountDetails.hobby_declaration_accepted"
            label="If no ABN; I confirm under the Pay As You Go legislation and guidelines produced by the ATO I provide you with this statement that, for the supply I am making and further suppliers of this type that I make to you: This supply is made to you in my capacity as an individual, and the supply is made in the course of an activity that is a private recreational pursuit or hobby."
          />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          @click="skipSettingDestination()"
        >
          Skip
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="!validateEntry()"
          text
          @click="saveDestination"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  export default {
    name: 'PrizeDestinationForm',
    data () {
      return {
        bankAccountDetails: {
          account_name: undefined,
          bank_name: undefined,
          bsb: undefined,
          account_number: undefined,
          abn: undefined,
          registered_for_gst: undefined,
        },
        abnRules: [
          v =>
            !!(v?.toString().length === 11) || 'Value must be exactly 11 digits',
        ],
        bsbRules: [
          v => !!v || 'This field is required',
          v => !!(v?.toString().length === 6) || 'Value must be exactly 6 digits',
        ],
        accountNumberRules: [
          v => !!v || 'This field is required',
          v =>
            !!(v?.toString().length >= 6 && v?.toString().length <= 10) ||
            'Value must have between 6 and 10 digits',
        ],
      }
    },
    computed: {
      ...mapGetters(['prizeDestinationDialog']),
    },
    mounted () {},
    methods: {
      ...mapActions(['saveRegistrantPrizeDestination']),
      ...mapMutations([
        'SET_PRIZEDESTINATIONDIALOG_STATE',
        'OPEN_MEDICAL_UPLOAD',
      ]),
      saveDestination () {
        if (this.validateEntry) {
          this.saveRegistrantPrizeDestination(this.bankAccountDetails).then(
            () => {
              this.SET_PRIZEDESTINATIONDIALOG_STATE(false)
              this.OPEN_MEDICAL_UPLOAD()
            },
          )
        }
      },
      validateEntry () {
        const noEmptyFields = this.allFieldsFilledIn()
        return noEmptyFields
      },
      allFieldsFilledIn () {
        return Object.keys(this.bankAccountDetails).every(detail => {
          if (detail === 'abn' || detail === 'registered_for_gst') {
            return true
          }

          return this.bankAccountDetails[detail] !== undefined
        })
      },
      skipSettingDestination () {
        this.OPEN_MEDICAL_UPLOAD()
        this.SET_PRIZEDESTINATIONDIALOG_STATE(false)
      },
    },
  }
</script>

<style></style>
