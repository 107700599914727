<template>
  <v-dialog
    v-model="uploadMedicalDialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Upload Medical Document:</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-file-input
            v-model="fileUploadData"
            accept="image/*,.pdf,.doc,.docx"
            truncate-length="15"
          />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          @click="skip()"
        >
          Skip
        </v-btn>
        <v-spacer />
        <v-btn
          text
          @click="saveUploadedFile()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from 'vuex'

  export default {
    name: 'UploadMedical',
    data () {
      return {
        fileUploadData: null,
      }
    },
    computed: {
      ...mapGetters(['uploadMedicalDialog', 'specificOrderSelected']),
    },
    mounted () {
      console.log('MOunting is complete')
    },
    created () {},
    methods: {
      ...mapActions(['saveMedical']),
      ...mapMutations(['CLOSE_MEDICAL_UPLOAD']),
      saveUploadedFile () {
        this.saveMedical({
          file: this.fileUploadData,
          orderId: this.specificOrderSelected._id,
        })
          .then(() => {
            console.log('Upload complete')
          })
          .finally(() => {
            this.CLOSE_MEDICAL_UPLOAD()
            this.$router.push('/registrant/orders')
          })
      },
      skip () {
        this.CLOSE_MEDICAL_UPLOAD()
        this.$router.push('/registrant/orders')
      },
    },
  }
</script>

<style></style>
