<template>
  <v-dialog
    v-model="productEntryDialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="rounded ma-2"
        dark
        tile
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-plus</v-icon>
        Additional Info
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Additional Info</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          These fields allow you to customise your order. (eg. Add prefered ID
          numbers, license numbers.)
          <span>
            <br>
            <i>Note: These do not gaurentee those numbers will be allocated to
              you.</i>
          </span>
          <v-row>
            <template v-for="(entry, i) in productEntrySlots">
              <template v-if="entry.visibility == 'Visible'">
                <v-col
                  v-if="entries.length > 0"
                  :key="entry._id"
                  cols="12"
                  sm="6"
                >
                  <template v-if="entry.prefix.length > 1">
                    <v-select
                      v-model="entries[i].prefix"
                      :items="entry.prefix"
                      label="Select Prefix"
                    />
                    <v-text-field
                      v-model="entries[i].value"
                      :label="entry.name"
                      :type="entry.type"
                      :rules="(entry.type == 'Number' ? numberRules : [])"
                    />
                  </template>
                  <template v-else>
                    <v-text-field
                      v-model="entries[i].value"
                      :type="entry.type"
                      :label="entry.name"
                      :prefix="entry.prefix"
                    />
                  </template>
                </v-col>
              </template>
            </template>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          @click="productEntryDialog = false"
        >
          Close
        </v-btn>
        <v-spacer />
        <v-btn
          text
          @click="assignOrderEntry"
        >
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    name: 'ProductEntryForm',
    props: {
      packageId: Object, // The name of this prop needs to be changed.
    },
    data () {
      return {
        productEntrySlots: [],
        productEntryDialog: false,
        entries: [],
        numberRules: [
          v => !!v || 'This field is required',
          v => (v && v >= 2) || 'Value cannot be less than 2',
          v => (v && v <= 99) || 'Value cannot be higher than 99',
        ],
      }
    },
    computed: {
      ...mapGetters([
        'orderEntry',
        'orderEntryCreated',
        'productEntry',
        'checkOptionEntry',
      ]),
    },
    mounted () {
      this.productEntrySlots = this.productEntry(this.packageId).filter(
        entry => entry.visibility === 'Visible',
      )

      this.productEntrySlots.forEach(productEntry =>
        this.entries.push({ prefix: '', value: '', product_entry: productEntry }),
      )
    },
    methods: {
      ...mapMutations(['CLEAR_ORDER_ENTRY', 'SET_OPTION_ENTRY']),
      assignOrderEntry () {
        this.CLEAR_ORDER_ENTRY()

        const newOrderEntryConfiguration = this.entries.map(entry => {
          return {
            value: entry.prefix + entry.value,
            product_entry: entry.product_entry._id,
          }
        })

        this.SET_OPTION_ENTRY(newOrderEntryConfiguration)
        this.productEntryDialog = false
      },
    },
  }
</script>

<style></style>
